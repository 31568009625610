import React from 'react';
import PropTypes from 'prop-types';
import {LexicalComposer} from '@lexical/react/LexicalComposer';
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import {AutoFocusPlugin} from '@lexical/react/LexicalAutoFocusPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import {HeadingNode, QuoteNode} from '@lexical/rich-text';
import {TableCellNode, TableNode, TableRowNode} from '@lexical/table';
import {ListItemNode, ListNode} from '@lexical/list';
import {AutoLinkNode, LinkNode} from '@lexical/link';
import {LinkPlugin} from '@lexical/react/LexicalLinkPlugin';
import {ListPlugin} from '@lexical/react/LexicalListPlugin';
import ToolbarPlugin from '../plugins/ToolbarPlugin';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {styled} from '@mui/material/styles';

/**
 * Renders the Empty Placeholder
 * @param {object} description Character description
 * @param {func} setDescription Character description update callback
 * @return {string} Rendered placeholder
 */
function Description({description, setDescription}) {
  const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    color: theme.palette.text.secondary,
    padding: theme.spacing(2),
  }));

  return (
    <Item>
      <LexicalComposer
        initialConfig={{
          onError(error) {
            throw error;
          },
          theme: {
            paragraph: 'paragraph',
            quote: 'quote',
            h1: 'h1',
            h2: 'h2',
            h3: 'h3',
            h4: 'h4',
            h5: 'h5',
            ol: 'ol',
            ul: 'ul',
            rtl: 'text-right',
            ltr: 'text-left',
            text: {
              bold: 'bold',
              italic: 'italic',
              underline: 'underline',
              strikethrough: 'strikethrough',
            },
          },
          nodes: [
            HeadingNode,
            ListNode,
            ListItemNode,
            QuoteNode,
            TableNode,
            TableCellNode,
            TableRowNode,
            AutoLinkNode,
            LinkNode,
          ],
          namespace: 'Description',
          editorState: JSON.stringify(description),
          editable: false,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <ToolbarPlugin onSubmit={setDescription}/>
          <RichTextPlugin
            contentEditable={<ContentEditable id="editor" />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <HistoryPlugin />
          <AutoFocusPlugin />
          <ListPlugin />
          <LinkPlugin />
        </Box>
      </LexicalComposer>
    </Item>
  );
}

export default Description;

Description.propTypes = {
  description: PropTypes.object,
  setDescription: PropTypes.func,
};
