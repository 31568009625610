import React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import {styled} from '@mui/material/styles';
import {useTheme} from '@mui/system';
import {isMobile} from 'react-device-detect';

import {stringifyModifier} from '../Utils';

/**
 * Renders the StatBlock component
 * @param {array} stats Callback for the Theme switching
 * @return {string} Rendered component
 */
function StatBlock({stats}) {
  const theme = useTheme();

  console.log(useMediaQuery(theme.breakpoints.down('lg')));
  const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: (
      useMediaQuery(theme.breakpoints.down('lg')) ?
      '10vh' : 100 / stats.length + '%'
    ),
    fontSizeAdjust: (isMobile ? '1' : 'inherit'),
  }));

  return (
    <Stack
      direction={useMediaQuery(theme.breakpoints.down('lg')) ? 'row' : 'column'}
      spacing={2}
      height='100%'
    >
      {stats.map((stat) => (
        <Item key={stat.name} sx={{width: '100%'}}>
          <Stack
            spacing={0}
            direction='column'
            height='100%'
            justifyContent="space-around"
          >
            {isMobile ?
              <Box id='statShort'>{stat.short}</Box> :
              <Box id='statName'>{stat.name}</Box>
            }
            <Box id='statValue'>{stat.value}</Box>
            <Box id='statModifier'>
              {stringifyModifier(stat.modifier)}
            </Box>
          </Stack>
        </Item>
      ))}
    </Stack>
  );
}

export default StatBlock;

StatBlock.propTypes = {
  stats: PropTypes.array,
};
