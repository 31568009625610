import React from 'react';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Toolbar from '@mui/material/Toolbar';
import {isMobile} from 'react-device-detect';

import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import LinkIcon from '@mui/icons-material/Link';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import EditIcon from '@mui/icons-material/Edit';

import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {useCallback, useEffect, useRef, useState} from 'react';
import {
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  REDO_COMMAND,
  UNDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  $getSelection,
  $isRangeSelection,
  $createParagraphNode,
} from 'lexical';
import {$isLinkNode, TOGGLE_LINK_COMMAND} from '@lexical/link';
/* eslint-disable camelcase */
import {
  $isAtNodeEnd,
  $setBlocksType_experimental,
} from '@lexical/selection';
import {$getNearestNodeOfType, mergeRegister} from '@lexical/utils';
import {
  REMOVE_LIST_COMMAND,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  $isListNode,
  ListNode,
} from '@lexical/list';
import {createPortal} from 'react-dom';
import {
  $createHeadingNode,
  $createQuoteNode,
  $isHeadingNode,
} from '@lexical/rich-text';
import PropTypes from 'prop-types';

const LowPriority = 1;

/**
 * Renders the Divider component
 * @param {object} editor Target editor
 * @param {object} rect Rectangular target component
 */
function positionEditorElement(editor, rect) {
  if (rect === null) {
    editor.style.opacity = '0';
    editor.style.top = '-1000px';
    editor.style.left = '-1000px';
  } else {
    editor.style.opacity = '1';
    editor.style.top = `${rect.top + rect.height + window.pageYOffset + 10}px`;
    editor.style.left = `${
      rect.left + window.pageXOffset - editor.offsetWidth / 2 + rect.width / 2
    }px`;
  }
}

/**
 * Renders the Divider component
 * @param {object} editor Target editor
 * @return {string} Rendered component
 */
function FloatingLinkEditor({editor}) {
  const editorRef = useRef(null);
  const inputRef = useRef(null);
  const mouseDownRef = useRef(false);
  const [linkUrl, setLinkUrl] = useState('');
  const [isEditMode, setEditMode] = useState(false);
  const [lastSelection, setLastSelection] = useState(null);

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
      } else {
        setLinkUrl('');
      }
    }
    const editorElem = editorRef.current;
    const nativeSelection = window.getSelection();
    const activeElement = document.activeElement;

    if (editorElem === null) {
      return;
    }

    const rootElement = editor.getRootElement();
    if (
      selection !== null &&
      !nativeSelection.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const domRange = nativeSelection.getRangeAt(0);
      let rect;
      if (nativeSelection.anchorNode === rootElement) {
        let inner = rootElement;
        while (inner.firstElementChild != null) {
          inner = inner.firstElementChild;
        }
        rect = inner.getBoundingClientRect();
      } else {
        rect = domRange.getBoundingClientRect();
      }

      if (!mouseDownRef.current) {
        positionEditorElement(editorElem, rect);
      }
      setLastSelection(selection);
    } else if (!activeElement || activeElement.className !== 'link-input') {
      positionEditorElement(editorElem, null);
      setLastSelection(null);
      setEditMode(false);
      setLinkUrl('');
    }

    return true;
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
        editor.registerUpdateListener(({editorState}) => {
          editorState.read(() => {
            updateLinkEditor();
          });
        }),

        editor.registerCommand(
            SELECTION_CHANGE_COMMAND,
            () => {
              updateLinkEditor();
              return true;
            },
            LowPriority,
        ),
    );
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor();
    });
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    if (isEditMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditMode]);

  return (
    <div ref={editorRef} className='link-editor'>
      {isEditMode ? (
        <input
          ref={inputRef}
          className='link-input'
          value={linkUrl}
          onChange={(event) => {
            setLinkUrl(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              if (lastSelection !== null) {
                if (linkUrl !== '') {
                  editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
                }
                setEditMode(false);
              }
            } else if (event.key === 'Escape') {
              event.preventDefault();
              setEditMode(false);
            }
          }}
        />
      ) : (
        <>
          <div className='link-input'>
            <a href={linkUrl} target='_blank' rel='noopener noreferrer'>
              {linkUrl}
            </a>
            <div
              className='link-edit'
              role='button'
              tabIndex={0}
              onMouseDown={(event) => event.preventDefault()}
              onClick={() => {
                setEditMode(true);
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}

FloatingLinkEditor.propTypes = {
  editor: PropTypes.object,
};

/**
 * Renders the Getter for selected node
 * @param {object} selection Selected value
 * @return {object} Selected Node
 */
function getSelectedNode(selection) {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }
}

/**
 * Renders the ToolbarPlugin component
 * @param {func} onSubmit Submit callback function
 * @return {string} Rendered ToolbarPlugin component
 */
export default function ToolbarPlugin({onSubmit}) {
  const [editor] = useLexicalComposerContext();
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [blockType, setBlockType] = useState('paragraph');
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [alignment, setAlignment] = useState('left');
  const [isEditable, setEditable] = useState(false);
  const alignments = ['left', 'left', 'center', 'right', 'justify'];

  const blockTypes = [
    {
      value: 'paragraph',
      label: 'Normal',
      callback: (() => formatParagraph()),
    }, {
      value: 'quote',
      label: 'Quote',
      callback: (() => formatQuote()),
    }, {
      value: 'h1',
      label: 'Large Heading (H1)',
      callback: (() => formatHeading('h1')),
    }, {
      value: 'h2',
      label: 'Small Heading (H2)',
      callback: (() => formatHeading('h2')),
    }, {
      value: 'h3',
      label: 'Heading (H3)',
      callback: (() => formatHeading('h3')),
    }, {
      value: 'h4',
      label: 'Heading (H4)',
      callback: (() => formatHeading('h4')),
    }, {
      value: 'h5',
      label: 'Heading (H5)',
      callback: (() => formatHeading('h5')),
    }, {
      value: 'ol',
      label: 'Numbered List',
      callback: (() => formatNumberedList()),
    }, {
      value: 'ul',
      label: 'Bulleted List',
      callback: (() => formatBulletList()),
    },
  ];

  const formatParagraph = () => {
    if (blockType !== 'paragraph') {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $setBlocksType_experimental(selection, () => $createParagraphNode());
        }
      });
    }
  };

  const formatHeading = (headingSize) => {
    if (blockType !== headingSize) {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $setBlocksType_experimental(selection, () =>
            $createHeadingNode(headingSize),
          );
        }
      });
    }
  };

  const formatBulletList = () => {
    if (blockType !== 'bullet') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatNumberedList = () => {
    if (blockType !== 'number') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatQuote = () => {
    if (blockType !== 'quote') {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $setBlocksType_experimental(selection, () => $createQuoteNode());
        }
      });
    }
  };

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === 'root' ?
        anchorNode : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element) ?
          element.getTag() : element.getType();
          setBlockType(type);
        }
      }

      // Update text format
      if (!isEditable) {
        setIsBold(false);
        setIsItalic(false);
        setIsUnderline(false);
        setIsStrikethrough(false);
      } else {
        setIsBold(selection.hasFormat('bold'));
        setIsItalic(selection.hasFormat('italic'));
        setIsUnderline(selection.hasFormat('underline'));
        setIsStrikethrough(selection.hasFormat('strikethrough'));
      }
      // Update text format
      if (!isEditable) {
        setAlignment('');
      } else {
        setAlignment(alignments.at(element.getFormat()));
      }

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
        editor.registerUpdateListener(({editorState}) => {
          editorState.read(() => {
            updateToolbar();
          });
        }),
        editor.registerCommand(
            SELECTION_CHANGE_COMMAND,
            (_payload, newEditor) => {
              updateToolbar();
              return false;
            },
            LowPriority,
        ),
        editor.registerCommand(
            CAN_UNDO_COMMAND,
            (payload) => {
              setCanUndo(payload);
              return false;
            },
            LowPriority,
        ),
        editor.registerCommand(
            CAN_REDO_COMMAND,
            (payload) => {
              setCanRedo(payload);
              return false;
            },
            LowPriority,
        ),
    );
  }, [editor, updateToolbar]);

  const handleBlockSelect = (event, child) => {
    setBlockType(event.target.value);
  };

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://');
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  return (
    <Toolbar
      sx={{
        minHeight: [36],
        maxHeight: [36],
        gap: 2,
        padding: [0],
      }}
    >
      <ButtonGroup>
        <Button
          disabled={!canUndo || !isEditable}
          onClick={() => {
            editor.dispatchCommand(UNDO_COMMAND);
          }}
          aria-label='Undo'
        >
          <UndoIcon />
        </Button>
        <Button
          disabled={!canRedo || !isEditable}
          onClick={() => {
            editor.dispatchCommand(REDO_COMMAND);
          }}
          aria-label='Redo'
        >
          <RedoIcon />
        </Button>
      </ButtonGroup>
      <FormControl
        size='small'
        sx={{
          maxHeight: (isMobile ? 36 : 36),
          minHeight: (isMobile ? 36 : 36),
          maxWidth: (isMobile ? 155 : 155),
          minWidth: (isMobile ? 155 : 155),
        }}
      >
        <Select
          value={blockType}
          onChange={handleBlockSelect}
          sx={{
            maxHeight: '36px',
            minHeight: '36px',
            maxWidth: '155px',
            minWidth: '155px',
          }}
          disabled={!isEditable}
        >
          {blockTypes.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              onClick={option.callback}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <ToggleButtonGroup
        sx={{
          minHeight: '36px',
          maxHeight: '36px',
        }}
        disabled={!isEditable}
      >
        <ToggleButton
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
          }}
          selected={isBold}
          aria-label='Format Bold'
        >
          <FormatBoldIcon />
        </ToggleButton>
        <ToggleButton
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
          }}
          selected={isItalic}
          aria-label='Format Italics'
        >
          <FormatItalicIcon />
        </ToggleButton>
        <ToggleButton
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
          }}
          selected={isStrikethrough}
          aria-label='Format Strikethrough'
        >
          <FormatStrikethroughIcon />
        </ToggleButton>
        <ToggleButton
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
          }}
          selected={isUnderline}
          aria-label='Format Underline'
        >
          <FormatUnderlinedIcon />
        </ToggleButton>
        <ToggleButton
          onClick={insertLink}
          selected={isLink}
          aria-label='Insert Link'
          disabled
        >
          <LinkIcon />
        </ToggleButton>
        {isLink &&
          createPortal(<FloatingLinkEditor editor={editor} />, document.body)}
        <Divider />
      </ToggleButtonGroup>
      <ToggleButtonGroup
        value={alignment}
        exclusive
        onChange={(event, newAlignment) => {
          if (!isEditable) {
            setAlignment('');
          } else if (newAlignment !== null) {
            setAlignment(newAlignment);
          }
        }}
        sx={{
          minHeight: '36px',
          maxHeight: '36px',
        }}
        disabled={!isEditable}
      >
        <ToggleButton
          onClick={() => {
            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
          }}
          value='left'
          aria-label='Left Align'
        >
          <FormatAlignLeftIcon />
        </ToggleButton>
        <ToggleButton
          onClick={() => {
            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center');
          }}
          value='center'
          aria-label='Center Align'
        >
          <FormatAlignCenterIcon />
        </ToggleButton>
        <ToggleButton
          onClick={() => {
            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right');
          }}
          value='right'
          aria-label='Right Align'
        >
          <FormatAlignRightIcon />
        </ToggleButton>
        <ToggleButton
          onClick={() => {
            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify');
          }}
          value='justify'
          aria-label='Justify Align'
        >
          <FormatAlignJustifyIcon />
        </ToggleButton>
      </ToggleButtonGroup>
      <Box width='100%'>
      </Box>
      <ToggleButton
        sx={{
          display: (isEditable ? 'none' : 'inline-flex'),
          minHeight: '36px',
          maxHeight: '36px',
        }}
        onClick={() => {
          editor.setEditable(true);
          setEditable(true);
        }}
        selected={isEditable}
        aria-label='Edit text'
      >
        <EditIcon />
      </ToggleButton>
      <ToggleButton
        sx={{
          display: (!isEditable ? 'none' : 'inline-flex'),
          minHeight: '36px',
          maxHeight: '36px',
        }}
        onClick={() => {
          onSubmit(editor.getEditorState().toJSON());
          editor.setEditable(false);
          setEditable(false);
        }}
      >
        Update
      </ToggleButton>
    </Toolbar>
  );
}

ToolbarPlugin.propTypes = {
  onSubmit: PropTypes.func,
};
