import {React, useState} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import * as Icons from '@mui/icons-material';
import Box from '@mui/system/Box';
import PropTypes from 'prop-types';
import {useTheme} from '@mui/material';

/**
 * Renders the RightDrawer component
 * @param {number} drawerWidth Callback for the Theme switching
 * @return {string} Rendered component
 */
function RightDrawer({drawerWidth}) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box
      open={open}
      sx={{
        display: 'flex',
        flexFlow: 'column',
        height: '100%',
        minWidth: (open ? drawerWidth : (theme.spacing(8))),
        maxWidth: (open ? drawerWidth : (theme.spacing(8))),
        width: (open ? drawerWidth : (theme.spacing(8))),
      }}
    >
      <List
        sx={{
          paddingTop: 0,
          height: '100%',
        }}
      >
      </List>
      <List
        sx={{
          paddingBottom: 0,
        }}
      >
        <ListItem disablePadding sx={{display: 'block'}}>
          <ListItemButton
            color="inherit"
            aria-label="close drawer"
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            sx={{
              height: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
              marginTop: 'auto',
            }}
          >
            <ListItemText
              primary='Close Drawer'
              sx={{
                opacity: open ? 1 : 0,
                textAlign: 'right',
              }}
            />
            <ListItemIcon
              sx={{
                minWidth: 0,
                ml: open ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              {open ?
              <Icons.KeyboardDoubleArrowRight /> :
              <Icons.KeyboardDoubleArrowLeft />}
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
}

export default RightDrawer;

RightDrawer.propTypes = {
  drawerWidth: PropTypes.number,
};
