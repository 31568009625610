import {React, useEffect, useState} from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';

import Description from './Description';
import StatBlock from './StatBlock';
import SkillBlock from './SkillBlock';
import {useTheme} from '@mui/material';

/**
 * Renders the Content component
 * @return {string} Rendered component
 */
function Content() {
  const theme = useTheme();
  const [character, setCharacter] = useState({
    stats: [],
    skills: [],
    description: null,
  });

  const getCharacter = () => {
    axios.get('test-character.json').then((response) => {
      setCharacter(response.data.character);
    });
  };

  useEffect(() =>{
    getCharacter();
  }, []);

  return (
    <Grid
      container
      spacing={2}
      sx={{
        paddingBottom: theme.spacing(2),
      }}
    >
      <Grid item xs={3} sm={3} md={12} lg={2} xl={1}>
        <StatBlock stats={character.stats} />
      </Grid>
      <Grid item xs={9} sm={9} md={12} lg={5} xl={4}>
        <SkillBlock skills={character.skills} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={7}>
        {character.description ?
        <Description
          description={character.description}
          setDescription={(content) => character.description = content}
        /> : <></>}
      </Grid>
    </Grid>
  );
}

export default Content;
