import React from 'react';
import PropTypes from 'prop-types';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Rating from '@mui/material/Rating';

import SquareIcon from '@mui/icons-material/Square';
import SquareOutlinedIcon from '@mui/icons-material/SquareOutlined';
import {isMobile} from 'react-device-detect';

import {stringifyModifier} from '../Utils';
import {useTheme} from '@mui/material';

/**
 * Renders the SkillBlock component
 * @param {array} skills Callback for the Theme switching
 * @return {string} Rendered component
 */
function SkillBlock({skills}) {
  const theme = useTheme();
  return (
    <TableContainer
      component={Paper}
      sx={{
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        color: theme.palette.text.secondary,
        height: '100%',
      }}
    >
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              Proficiency
            </TableCell>
            <TableCell>
              Stat
            </TableCell>
            <TableCell>
              Name
            </TableCell>
            <TableCell align='right'>
              Modifier
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {skills.map((skill, index) => (
            <TableRow key={skill.name}>
              <TableCell>
                <Rating
                  readOnly
                  name={skill.name}
                  value={skill.proficiency}
                  max={4}
                  icon={
                    <SquareIcon
                      fontSize={isMobile ? 'large' : 'medium'}
                    />
                  }
                  emptyIcon={
                    <SquareOutlinedIcon
                      fontSize={isMobile ? 'large' : 'medium'}
                    />
                  }
                />
              </TableCell>
              <TableCell>
                {skill.stat.toUpperCase()}
              </TableCell>
              <TableCell>
                {skill.name}
              </TableCell>
              <TableCell align='right'>
                {stringifyModifier(skill.modifier)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default SkillBlock;

SkillBlock.propTypes = {
  skills: PropTypes.array,
};
