/**
 * Creates a String version of the modifier with a + or - sign
 * @param {number} modifier Numerical modifer
 * @return {string} String version of the modifier with approriate sign
 */
export function stringifyModifier(modifier) {
  if (modifier > 0) {
    return '+' + modifier.toString();
  }
  return modifier.toString();
}
