import React, {useState} from 'react';
import {createTheme, ThemeProvider, useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import {isMobile} from 'react-device-detect';

import LeftDrawer from './components/LeftDrawer';
import RightDrawer from './components/RightDrawer';
import TopBar from './components/TopBar';
import Content from './components/Content';

import './App.css';

/**
 * Renders main app component
 * @return {string} Rendered component
 */
function App() {
  const theme = useTheme();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = useState(prefersDarkMode ? 'dark' : 'light');
  const leftDrawerWidth = 256;
  const rightDrawerWidth = 256;

  const appTheme = createTheme({
    palette: {mode: mode},
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1500,
      },
    },
  });

  const handleModeSwitch = () => {
    if (mode === 'dark') {
      setMode('light');
    } else {
      setMode('dark');
    }
  };

  return (
    <ThemeProvider theme={appTheme}>
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'column',
          height: '100vh',
          fontSizeAdjust: (isMobile ? '1.25' : 'inherit'),
          lineHeight: (isMobile ? '3' : 'inherit'),
          backgroundColor: appTheme.palette.background.default,
        }}
      >
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            height: '64px',
          }}
        >
          <TopBar
            mode={mode}
            handleModeSwitch={handleModeSwitch}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'row',
            height: 'calc(100vh - 128px)',
          }}
        >
          <Box
            sx={{
              display: (
                useMediaQuery(theme.breakpoints.up('lg')) ? 'flex' : 'none'
              ),
            }}
          >
            <LeftDrawer drawerWidth={leftDrawerWidth} />
          </Box>
          <Paper
            elevation={3}
            sx={{
              display: 'flex',
              width: '100%',
              overflowY: 'auto',
              backgroundColor: (mode === 'dark' ? '#0b0905' : '#ede7db'),
              scrollbarWidth: 'none',
            }}
          >
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                p: 2,
              }}
            >
              <Content />
            </Box>
          </Paper>
          <Box
            sx={{
              display: (
                useMediaQuery(theme.breakpoints.up('lg')) ? 'flex' : 'none'
              ),
            }}
          >
            <RightDrawer drawerWidth={rightDrawerWidth} />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            height: '64px',
          }}
        >
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default App;
