import React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import * as Icons from '@mui/icons-material';
import PropTypes from 'prop-types';

/**
 * Renders the TopBar component
 * @param {string} mode mode object
 * @param {func} handleModeSwitch Callback for the mode switching
 * @return {string} Rendered component
 */
function TopBar({mode, handleModeSwitch}) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Box
      position='none'
      sx = {{
        padding: '0 20px',
        display: 'flex',
        flexFlow: 'row',
        width: '100%',
      }}
    >
      <Toolbar
        disableGutters
        sx = {{
          display: 'flex',
          flexFlow: 'row',
          width: '100%',
        }}
      >
        <AdbIcon sx={{display: {md: 'none', lg: 'flex'}, mr: 1}} />
        <Typography
          variant='h6'
          noWrap
          component='a'
          href='/'
          sx={{
            mr: 2,
            display: {md: 'none', lg: 'flex'},
            fontFamily: 'monospace',
            fontWeight: 700,
            letterSpacing: '.3rem',
            color: 'inherit',
            textDecoration: 'none',
            overflow: 'inherit',
          }}
        >
          Character Builder
        </Typography>
        <Box
          sx={{
            width: '100%',
            flexGrow: 1,
            display: {md: 'none', lg: 'flex'},
          }}
        >
          <Button
            onClick={handleCloseNavMenu}
            sx={{my: 2, color: 'inherit', display: 'block'}}
          >
            Characters
          </Button>
        </Box>

        <Box sx={{flexGrow: 1, display: {md: 'flex', lg: 'none'}}}>
          <IconButton
            size='large'
            aria-label='account of current user'
            aria-controls='menu-appbar'
            aria-haspopup='true'
            onClick={handleOpenNavMenu}
            color='inherit'
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id='menu-appbar'
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: {md: 'block', lg: 'none'},
            }}
          >
            <MenuItem onClick={handleCloseNavMenu}>
              <Typography textAlign='center'>Characters</Typography>
            </MenuItem>
          </Menu>
        </Box>
        <AdbIcon sx={{display: {md: 'block', lg: 'none'}, mr: 1}} />
        <Typography
          variant='h5'
          noWrap
          component='a'
          href=''
          sx={{
            mr: 2,
            display: {md: 'block', lg: 'none'},
            flexGrow: 1,
            fontFamily: 'monospace',
            fontWeight: 700,
            letterSpacing: '.3rem',
            color: 'inherit',
            textDecoration: 'none',
          }}
        >
          Character Builder
        </Typography>
        <Box sx={{flexGrow: 0}}>
          <Tooltip title='Open settings'>
            <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
              <Avatar alt='Remy Sharp' src='/static/images/avatar/2.jpg' />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{mt: '45px'}}
            id='menu-appbar'
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem onClick={handleCloseUserMenu}>
              <Typography textAlign='center'>Logout</Typography>
            </MenuItem>
            <MenuItem onClick={handleModeSwitch}>
              {mode === 'dark' ?
              <Icons.DarkMode /> : <Icons.LightMode />}
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </Box>
  );
}

export default TopBar;

TopBar.propTypes = {
  mode: PropTypes.string,
  handleModeSwitch: PropTypes.func,
};
